import { render, staticRenderFns } from "./medicalcare.vue?vue&type=template&id=4fce710a&scoped=true&"
import script from "./medicalcare.vue?vue&type=script&lang=js&"
export * from "./medicalcare.vue?vue&type=script&lang=js&"
import style0 from "./medicalcare.vue?vue&type=style&index=0&id=4fce710a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fce710a",
  null
  
)

export default component.exports